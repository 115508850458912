



















































































import { Vue, Component } from 'vue-property-decorator';
import VerifyingViewModel from
  '@/vue-app/view-models/components/update-identity-document/verifying-view-model';

@Component({
  name: 'UpdateIdentityDocumentIdentityVerifying',
  components: {
    ContractTimeout: () => import('@/vue-app/components/contracts/timeout/ContractTimeout.vue'),
  },
})
export default class UpdateIdentityDocumentIdentityVerifying extends Vue {
  verifying_view_model = Vue.observable(new VerifyingViewModel(this));

  created() {
    this.verifying_view_model.initialize();
  }
}

